@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin reload-component-theme($theme) {

  $warn: map-get($theme, warn);

  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing.warn-backdrop {
    background: mat.get-color-from-palette($warn);
    opacity: 0.85 !important;
  }
}
