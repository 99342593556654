@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin timer-component-theme($theme) {

  $warn: map-get($theme, warn);

  .overdue {
    color: mat.get-color-from-palette($warn);
  }
}
